import React from 'react';
import { FormattedMessage } from 'react-intl';

const PasswordModalContent = () => (
  <>
    <h2>
      <FormattedMessage id="pages.about.security.accordion.how-you-can.bullet.password-link-text" />.
    </h2>
    <p>
      <FormattedMessage id="pages.about.security.accordion.how-you-can.bullet.password-modal-content" />
    </p>
  </>
);

export default PasswordModalContent;
