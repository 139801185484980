import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import Accordion from '@cof/graffiti-alley-spray-cans/molecules/Accordion';
import AccordionSection from '@cof/graffiti-alley-spray-cans/molecules/Accordion/AccordionSection';
import ModalLink from '@cof/graffiti-alley-spray-cans/molecules/ModalLink';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO/SEO';
import PasswordModalContent from '../../components/ModalContent/PasswordModalContent';

import './security.scss';

const Security = injectIntl(({ intl }) => {
  const createList = ({ prefix, size, isNumerical, values }) => {
    const elements = [];
    for (let i = 0; i < size; i++) {
      elements.push(
        <li key={`${prefix}${i}`}>
          <FormattedMessage id={`${prefix}${i}`} values={values?.[i] ?? {}} />
        </li>,
      );
    }
    return isNumerical ? <ol>{elements}</ol> : <ul>{elements}</ul>;
  };

  const abuseEmailLink = (
    <Link native="true" to="mailto:abuse@capitalone.com">
      <FormattedMessage id="pages.about.security.abuse-email-text" />
    </Link>
  );

  const contactUsLink = (
    <Link
      to="/contact-us/capitalonecards/"
      target="_blank"
      rel="noopener"
      title={intl.formatMessage({ id: 'pages.about.security.open-new-window' })}
      className="security-contact-us-link"
    >
      <FormattedMessage id="pages.about.security.contact-us-text" />
    </Link>
  );

  const disclosureLink = (
    <Link
      to="https://www.capitalone.com/digital/responsible-disclosure/"
      title={intl.formatMessage({ id: 'pages.about.security.open-new-window' })}
    >
      <FormattedMessage id="pages.about.security.accordion.suspected-vulnerability.disclosure-text" />
    </Link>
  );

  const passwordModal = (
    <ModalLink target={PasswordModalContent} title=" " linkIcon={false} intl={intl} size="large">
      <FormattedMessage id="pages.about.security.accordion.how-you-can.bullet.password-link-text" />
    </ModalLink>
  );

  const fraudLink = (
    <Link
      to={intl.formatMessage({ id: 'pages.about.security.fraud-link' })}
      // TODO: remove native property (internal) or update URL if (external)
      native="true"
    >
      <FormattedMessage id="pages.about.security.fraud-link-text" />
    </Link>
  );

  return (
    <Layout pageName="about-us-security" className="about-us-security">
      <div className="page-content-wrapper">
        <section>
          <h1>
            <FormattedMessage id="pages.about.security.title0" />
          </h1>
          <p>
            <FormattedMessage id="pages.about.security.p0" />
          </p>
          <p>
            <FormattedMessage id="pages.about.security.p1" />
          </p>
          <h3>
            <FormattedMessage id="pages.about.security.title1" />
          </h3>
          <p>
            <FormattedMessage id="pages.about.security.p2" />
          </p>
          {createList({ prefix: 'pages.about.security.numeric.', size: 3, isNumerical: true })}
          <p>
            <FormattedMessage id="pages.about.security.p3" />
          </p>
          <Accordion>
            <AccordionSection title={<FormattedMessage id="pages.about.security.accordion.phishing.title" />}>
              <p>
                <strong>
                  <FormattedMessage id="pages.about.security.accordion.phishing.b0" />
                </strong>
              </p>
              <p>
                <FormattedMessage id="pages.about.security.accordion.phishing.p0" />
              </p>
              <p>
                <strong>
                  <FormattedMessage id="pages.about.security.accordion.phishing.b1" />
                </strong>
              </p>
              <p>
                <FormattedMessage id="pages.about.security.accordion.phishing.p1" />
              </p>
              <p>
                <FormattedMessage id="pages.about.security.accordion.phishing.p2" />
              </p>
              {createList({ prefix: 'pages.about.security.accordion.phishing.bullet0.', size: 5 })}
              <p>
                <FormattedMessage id="pages.about.security.accordion.phishing.p3" />
              </p>
              <p>
                <strong>
                  <FormattedMessage id="pages.about.security.accordion.phishing.b2" />
                </strong>
              </p>
              {createList({
                prefix: 'pages.about.security.accordion.phishing.bullet1.',
                size: 5,
                values: { 2: { abuseEmailLink } },
              })}
              <p>
                <FormattedMessage id="pages.about.security.accordion.phishing.p4" values={{ contactUsLink }} />
              </p>
              <p>
                <strong>
                  <FormattedMessage id="pages.about.security.accordion.phishing.b3" />
                </strong>
              </p>
              <p>
                <FormattedMessage
                  id="pages.about.security.accordion.phishing.p5"
                  values={{
                    doNotProvide: (
                      <u>
                        <FormattedMessage id="pages.about.security.accordion.phishing.do-not-provide" />
                      </u>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage id="pages.about.security.accordion.phishing.p6" />
              </p>
              {createList({
                prefix: 'pages.about.security.accordion.phishing.numeric0.',
                size: 2,
                isNumerical: true,
                values: { 0: { abuseEmailLink } },
              })}
              <p>
                <FormattedMessage id="pages.about.security.accordion.phishing.p7" values={{ contactUsLink }} />
              </p>
              <p>
                <strong>
                  <FormattedMessage id="pages.about.security.accordion.phishing.b4" />
                </strong>
              </p>
              <p>
                <FormattedMessage
                  id="pages.about.security.accordion.phishing.p8"
                  values={{
                    doNotRespond: (
                      <u>
                        <FormattedMessage id="pages.about.security.accordion.phishing.do-not-respond" />
                      </u>
                    ),
                    useCaution: (
                      <u>
                        <FormattedMessage id="pages.about.security.accordion.phishing.use-caution" />
                      </u>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage id="pages.about.security.accordion.phishing.p9" />
              </p>
              {createList({
                prefix: 'pages.about.security.accordion.phishing.numeric1.',
                size: 3,
                isNumerical: true,
                values: { 0: { abuseEmailLink } },
              })}
              <p>
                <FormattedMessage id="pages.about.security.accordion.phishing.p10" values={{ contactUsLink }} />
              </p>
            </AccordionSection>
            <AccordionSection
              title={<FormattedMessage id="pages.about.security.accordion.suspected-vulnerability.title" />}
            >
              <p>
                <FormattedMessage id="pages.about.security.accordion.suspected-vulnerability.p0" />
              </p>
              <p>
                <FormattedMessage
                  id="pages.about.security.accordion.suspected-vulnerability.p1"
                  values={{ disclosureLink }}
                />
              </p>
            </AccordionSection>
            <AccordionSection title={<FormattedMessage id="pages.about.security.accordion.communicate-safely.title" />}>
              <p>
                <strong>
                  <FormattedMessage id="pages.about.security.accordion.communicate-safely.b0" />
                </strong>
              </p>
              {createList({ prefix: 'pages.about.security.accordion.communicate-safely.bullet0.', size: 3 })}
              <p>
                <strong>
                  <FormattedMessage id="pages.about.security.accordion.communicate-safely.b1" />
                </strong>
              </p>
              {createList({
                prefix: 'pages.about.security.accordion.communicate-safely.bullet1.',
                size: 6,
                values: {
                  0: { abuseEmailLink },
                  4: { contactUsLink },
                },
              })}
            </AccordionSection>
            <AccordionSection title={<FormattedMessage id="pages.about.security.accordion.how-we-keep.title" />}>
              {createList({ prefix: 'pages.about.security.accordion.how-we-keep.bullet.', size: 3 })}
            </AccordionSection>
            <AccordionSection title={<FormattedMessage id="pages.about.security.accordion.how-you-can.title" />}>
              {createList({
                prefix: 'pages.about.security.accordion.how-you-can.bullet.',
                size: 12,
                values: {
                  0: { passwordModal },
                  9: {
                    rememberMe: (
                      <strong>
                        <FormattedMessage id="pages.about.security.accordion.how-you-can.bullet.remember-me-text" />
                      </strong>
                    ),
                  },
                  11: { abuseEmailLink },
                },
              })}
            </AccordionSection>
            <AccordionSection title={<FormattedMessage id="pages.about.security.accordion.social-media.title" />}>
              <p>
                <FormattedMessage
                  id="pages.about.security.accordion.social-media.p0"
                  values={{
                    abuseEmailLink,
                    forwardCommunication: (
                      <u>
                        <FormattedMessage id="pages.about.security.accordion.social-media.forward-communication-text" />
                      </u>
                    ),
                  }}
                />
              </p>
            </AccordionSection>
            <AccordionSection title={<FormattedMessage id="pages.about.security.accordion.mfa.title" />}>
              <p>
                <FormattedMessage id="pages.about.security.accordion.mfa.p0" />
              </p>
            </AccordionSection>
          </Accordion>
          <p className="fraud-protection-link">
            <FormattedMessage id="pages.about.security.p4" values={{ fraudLink }} />
          </p>
        </section>
      </div>
    </Layout>
  );
});

export default Security;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.about.security.page-title"
    descriptionKey="pages.about.security.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
